const Resume = {
  title: "Resume",
  id: "resume",
  type: "link",
  description: "A collection of my resume",
  icon: "./images/notepad-icon.ico",
  link: "https://drive.google.com/file/d/1NtdLG-bxDddgN1xTuiMviGNiZsdVulv9/view?usp=sharing",
};

export default Resume;
